<template>
    <v-container class="feedback-container">
        <div class="feedback-child-container">
            <span class="feedback-header mb-3">We'd love to hear your feedback!</span>
            <p class="feedback-subtitle mt-3">
                What do you think of the overall experience with the Seamless Source eco-system so far?
            </p>
            <div class="icons-parent-contaienr">
                <div class="icons-child-contaienr" v-for="icon in icons" v-bind:key="icon.id" @click="emojiClicked(icon.message)">
                    <v-img class="emoji-image" :src="icon.imageUrl"/>
                </div>
            </div>
            <v-form @submit.prevent="submitForm">
                <p class="feedback-title">
                    Enter your feedback here:
                </p>
                <v-textarea v-model="feedback" outlined></v-textarea>
                <ui-button class="primary feedback-submit-btn" type="submit" :disabled="!this.feedback.length" title="Submit"/>
            </v-form>
        </div>
    </v-container>
</template>

<script>
import notification from '../../notification'
import restAdapter from '../../restAdapter'
import { confirmBox, resetLeaveAlert, setLeaveAlert } from '../../utils/confirmBox'

export default {
    name: "FeedbackForm",
    data: () => ({
        feedback: "",
        ignoreFormEdit: false,
        icons:[
        {
            id:1,
            imageUrl: require(`../../assets/feedback-icons/poor_icon.png`),
            message:"Poor"
        },
        {
            id:2,
            imageUrl: require(`../../assets/feedback-icons/average_icon.png`),
            message:"Average"
        },
        {
            id:3,
            imageUrl: require(`../../assets/feedback-icons/good_icon.png`),
            message:"Good"
        },
        {
            id:4,
            imageUrl: require(`../../assets/feedback-icons/excellent_icon.png`),
            message:"Excellent"
        }
        ],
    }),
    watch: {
        feedback: {
            handler: function(){
                this.ignoreFormEdit = setLeaveAlert(this.ignoreFormEdit);
            }
        }
    },
    methods: {
        async submitForm() {
            try {
                await restAdapter.post("/api/submit_feedback", {
                    feedback: this.feedback
                })
                this.ignoreFormEdit = true;
                this.feedback = ""
                resetLeaveAlert();
                notification.success("Thanks for your feedback. We'll get back to you as soon as possible.")
            } catch (error) {
                notification.errors(error.response.data.error)
            }
        },
        emojiClicked(message){
            this.feedback = message;
        }
    },

    beforeRouteLeave(to, from, next) {
        confirmBox(this.$store.state.routeLeaveAlert, next);
    },
};
</script>

<style scoped>

.feedback-container{
    margin-top: 15px;
    background-color: #FFFFFF;
    min-height: 80vh;
    box-shadow: 0px 3px 6px rgba(71, 48, 104, 0.06);
    border-radius: 7px;
    display: flex;
    justify-content: center;
}

.feedback-child-container{
    max-width: 32%;
    display: grid;
    justify-content: center;
}

.feedback-header{
    margin-top: 30px;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
    color: #473068;
}

.feedback-subtitle{
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    color: #442D65;
    text-align: justify;
}

.emoji-image{
    height: 40px;
    width: 40px;
}

.icons-parent-contaienr{
    height: 100px; 
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.icons-child-contaienr{
    margin: 10px 0px 20px 0px;
    display: inline-block;
    background: #FFFFFF;
    border: 0.5px solid #B4AECF;
    border-radius: 13px;
    padding: 15px;
    cursor: pointer;
    /* zoom: 1; */
}

.icons-child-contaienr:hover{
    box-shadow: 0px 4px 9px rgba(71, 192, 190, 0.53);
}

.feedback-title{
    font-weight: 600;
    font-size: 14px;
    line-height: 116.1%;
    color: #442D65;
    text-align: left;
}

.feedback-submit-btn{
    margin-right: 0px !important;
}

</style>